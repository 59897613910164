import { gql } from '@apollo/client';

export interface ContractProps {
  id: string;
  crmId: string;
  contractNumber: string;
  operation: string;
  energyType: string;
  modulation: string;
  sazoPercent: number;
  flexPositive: number;
  flexNegative: number;
  startDate: string;
  endDate: string;
  dueDateType: string;
  dueDateValue: string;
  contractFlags: string[];
  usageFactor: number;
  submarket: string;
  classification: string;
  tenant: {
    name: string;
  };
  account: {
    name: string;
  };
  cceeRepresentative: {
    name: string;
  };
  currentContractItem: {
    amount: number;
    readjustedAmount: number;
    finalAmount: number;
    finalQuantityMwm: number;
    quantityMwm: number;
    calculatedAmount: number;
  };
}

export interface ResponseProps {
  contracts: ContractProps[];
}

export interface PayloadProps {
  type: string;
  filter: {
    search?: string;
    operation?: string;
    periodType?: string;
  };
  limit?: number;
  offset?: number;
}

const GET_CONTRACTS = gql`
  query contracts($filter: ContractListFilter!, $type: ContractTypes!, $limit: Int, $offset: Int) {
    contracts(filter: $filter, type: $type, limit: $limit, offset: $offset) {
      id
      crmId
      contractNumber
      periodType
      operation
      startDate
      endDate
      energyType
      modulation
      sazoPercent
      flexPositive
      flexNegative
      dueDateType
      dueDateValue
      submarket
      contractFlags
      usageFactor
      classification
      tenant {
        name
      }
      account {
        name
      }
      cceeRepresentative {
        name
      }
      currentContractItem {
        amount
        quantityMwm
        finalAmount
        finalQuantityMwm
        readjustedAmount
        calculatedAmount
      }
    }
  }
`;

export default GET_CONTRACTS;
