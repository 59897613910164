import { useMemo } from 'react';

import { OpenInNew, CloseSharp } from '@material-ui/icons';
import { Grid, GridItem, Flex, Text, Divider, Button, Badge, Link, IconButton, Tooltip } from '@tradener/lumen';

import { ContractProps } from '@graphql/query/contractsDetails';

import { numberFormat } from '~/DEPRECATED/utils/format';

import ContractsDetailsSkeleton from './ContractsDetailsSkeleton';

export interface ContractsDetailsProps {
  isLoaded?: boolean;
  onClose?: () => void;
  contract: ContractProps;
}

export const formatDate = (date?: string) => {
  if (!date) return '-';

  return date
    ?.replace(/.{3}$/, '')
    .split('-')
    .reduce((acc, cur) => `${cur}/${acc}`);
};

export const formatRangeDate = (startDate?: string, endDate?: string) => {
  if (!startDate || !endDate) return '-';

  const [startYear, startMonth] = startDate.slice(2, 7).split('-');
  const [endYear, endMonth] = endDate.slice(2, 7).split('-');

  return `${startMonth}/${startYear} - ${endMonth}/${endYear}`;
};

export const formatPrice = (price?: number) => {
  if (!price) return '-';

  return price.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
  });
};

const ContractsDetails: React.ComponentType<Partial<ContractsDetailsProps>> = ({ isLoaded, contract, onClose }) => {
  const contractNumber = useMemo(() => contract?.contractNumber ?? '-', [contract?.contractNumber]);
  const energyType = useMemo(() => contract?.energyType ?? '-', [contract?.energyType]);
  const modulation = useMemo(() => contract?.modulation ?? '-', [contract?.modulation]);
  const sazoPercent = useMemo(() => (contract?.sazoPercent ? contract?.sazoPercent + '%' : '-'), [contract?.sazoPercent]);
  const flexPositive = useMemo(() => (contract?.flexPositive ? contract?.flexPositive + '%' : '-'), [contract?.flexPositive]);
  const flexNegative = useMemo(() => (contract?.flexNegative ? contract?.flexNegative + '%' : '-'), [contract?.flexNegative]);
  const rangeDate = useMemo(
    () => formatRangeDate(contract?.startDate, contract?.endDate),
    [contract?.endDate, contract?.startDate],
  );
  const classification = useMemo(() => contract?.classification ?? '-', [contract?.classification]);
  const cceeRepresentativeName = useMemo(() => contract?.cceeRepresentative?.name ?? '-', [contract?.cceeRepresentative]);
  const calculatedAmount = useMemo(() => {
    if (!contract?.currentContractItem?.calculatedAmount) return '-';

    return formatPrice(contract?.currentContractItem?.calculatedAmount) + ' / MWh';
  }, [contract?.currentContractItem?.calculatedAmount]);
  const quantityMwm = useMemo(
    () => numberFormat(contract?.currentContractItem?.quantityMwm, 3, 3) + ' MWm',
    [contract?.currentContractItem?.quantityMwm],
  );
  const contractFlags = useMemo(() => contract?.contractFlags ?? [], [contract?.contractFlags]);
  const dueDate = useMemo(() => {
    if (contract?.dueDateType === 'BUSINESS_DAY') return `${contract?.dueDateValue}º dia útil`;

    return `Dia ${contract?.dueDateValue}`;
  }, [contract?.dueDateType, contract?.dueDateValue]);
  const usageFactor = useMemo(
    () => (contract?.usageFactor ? numberFormat(contract?.usageFactor * 100, 2, 2) + '%' : '-'),
    [contract?.usageFactor],
  );
  const submarket = useMemo(() => contract?.submarket ?? '-', [contract?.submarket]);
  const tenant = useMemo(() => contract?.tenant?.name ?? '-', [contract?.tenant]);
  const account = useMemo(() => contract?.account?.name ?? '-', [contract?.account]);

  if (!isLoaded) return <ContractsDetailsSkeleton />;

  return (
    <Flex flexDir="column" padding="3" gap="1rem" bgColor="gray.0" borderRadius=".75rem" width="25rem">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        width="full"
        bgColor="gray.50"
        borderRadius=".5rem"
        padding="3"
        height="14"
      >
        <Flex alignItems="center" gap=".375rem" height="full">
          <Flex
            alignItems="center"
            justifyContent="center"
            borderRadius="50%"
            width="6"
            height="6"
            color="#FFF"
            bgColor="green.500"
          >
            {contract?.operation === 'SALE' ? 'V' : 'C'}
          </Flex>
          <Text variant="body-2-semibold" fontWeight="600">
            {contractNumber}
          </Text>
        </Flex>
        <Flex alignItems="center" justifyContent="flex-end" gap={2}>
          <Button
            as={Link}
            href={`${process.env.REACT_APP_SALESFORCE_URL}/lightning/r/Contract/${contract?.crmId}/view`}
            isExternal
            target="_blank"
            color="inherit"
            variant="outline"
            colorScheme="gray"
            size="sm"
            leftIcon={<OpenInNew style={{ width: '1.25rem', height: '1.25rem' }} />}
          >
            Salesforce
          </Button>
          {onClose && (
            <IconButton
              aria-label="Fechar"
              size="sm"
              variant="ghost"
              colorScheme="gray"
              onClick={onClose}
              icon={<CloseSharp />}
            />
          )}
        </Flex>
      </Flex>
      <Grid templateColumns="repeat(3, 1fr)" width="full" gap="4" padding="2">
        <GridItem colSpan={3}>
          <Text variant="body-2" fontWeight="600">
            Detalhes
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
            <Text variant="body-2-medium" color="gray.500">
              Empresa
            </Text>
            <Text variant="body-2">{tenant}</Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={2}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
            <Text variant="body-2-medium" color="gray.500">
              Conta
            </Text>
            <Tooltip label={account} placement="top" hasArrow>
              <Text variant="body-2" noOfLines={1}>
                {account}
              </Text>
            </Tooltip>
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
            <Text variant="body-2-medium" color="gray.500">
              Vigência
            </Text>
            <Text variant="body-2">{rangeDate}</Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
            <Text variant="body-2-medium" color="gray.500">
              Volume
            </Text>
            <Text variant="body-2">{quantityMwm}</Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
            <Text variant="body-2-medium" color="gray.500">
              Preço atual
            </Text>
            <Text variant="body-2">{calculatedAmount}</Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
            <Text variant="body-2-medium" color="gray.500">
              Tipo do Contrato
            </Text>
            <Text variant="body-2">{classification}</Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={2}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
            <Text variant="body-2-medium" color="gray.500">
              Representante CCEE
            </Text>
            <Text variant="body-2">{cceeRepresentativeName}</Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={3} height="1px">
          <Divider margin="0" />
        </GridItem>
        <GridItem colSpan={3}>
          <Text variant="body-2" fontWeight="600">
            Energia
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
            <Text variant="body-2-medium" color="gray.500">
              Submercado
            </Text>
            <Text variant="body-2">{submarket}</Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
            <Text variant="body-2-medium" color="gray.500">
              Tipo
            </Text>
            <Text variant="body-2">{energyType}</Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
            <Text variant="body-2-medium" color="gray.500">
              Modulação
            </Text>
            <Text variant="body-2">{modulation}</Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
            <Text variant="body-2-medium" color="gray.500">
              Sazonalização
            </Text>
            <Text variant="body-2">{sazoPercent}</Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
            <Text variant="body-2-medium" color="gray.500">
              Flex +
            </Text>
            <Text variant="body-2">{flexPositive}</Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
            <Text variant="body-2-medium" color="gray.500">
              Flex -
            </Text>
            <Text variant="body-2">{flexNegative}</Text>
          </Flex>
        </GridItem>
        {contractFlags?.length > 0 && (
          <GridItem colSpan={3}>
            <Flex flexDir="column" alignItems="flex-start" justifyContent="center" gap="1">
              <Text variant="body-2-medium" color="gray.500">
                Sinalizador
              </Text>
              <Flex alignItems="center" justifyContent="flex-start" flexWrap="wrap" gap="3" maxWidth="22rem">
                {contractFlags?.map((flag) => (
                  <Badge key={flag} variant="outline" colorScheme="gray">
                    <Text variant="body-1">{flag}</Text>
                  </Badge>
                ))}
              </Flex>
            </Flex>
          </GridItem>
        )}
        <GridItem colSpan={3} height="1px">
          <Divider margin="0" />
        </GridItem>
        <GridItem colSpan={3}>
          <Text variant="body-2-semibold" fontWeight="600">
            Outros
          </Text>
        </GridItem>
        <GridItem colSpan={1}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
            <Text variant="body-2" color="gray.500">
              Data de pag.
            </Text>
            <Text variant="body-2">{dueDate}</Text>
          </Flex>
        </GridItem>
        <GridItem colSpan={2}>
          <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
            <Text variant="body-2" color="gray.500">
              Fator de uso
            </Text>
            <Text variant="body-2">{usageFactor}</Text>
          </Flex>
        </GridItem>
      </Grid>
    </Flex>
  );
};

export default ContractsDetails;
